import React from "react"
import styled from "styled-components"

const MainContainer = styled.div`
  width: 120px;
  height: 120px;
  position: relative;
  overflow: hidden;
  margin: 0.5rem 1rem 1rem 1rem;
  @media (min-width: 768px) {
    margin: 0.5rem 2rem 1rem 2rem;
  }
`

const Supporter = props => {
  return (
    <MainContainer>
      <img
        src={props.photo}
        alt={props.name}
        style={{
          margin: "0 auto",
          height: "100%",
          width: "auto",
        }}
      />
    </MainContainer>
  )
}

export default Supporter
