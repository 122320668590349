import React from "react"
import styled from "styled-components"
import Header from "../components/header"
import Reveal from "react-reveal/Reveal"
import Footer from "../components/footer"
import Supporter from "../components/supporter"
import SEO from "../components/seo"
import { useStaticQuery, graphql, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { INLINES } from "@contentful/rich-text-types"
import Logo from "../images/logo-new2.png"
import TeamPhoto from "../images/team-photo.png"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

const website_url = "https://youchangeearth.org"
const options = {
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderNode: {
    [INLINES.HYPERLINK]: node => {
      return (
        <a
          href={node.data.uri}
          target={`${
            node.data.uri.startsWith(website_url) ? "_self" : "_blank"
          }`}
          rel={`${
            node.data.uri.startsWith(website_url) ? "" : "noopener noreferrer"
          }`}
          style={{ textDecoration: "none", color: "var(--green)" }}
        >
          {node.content[0].value}
        </a>
      )
    },
  },
}

const ResponsiveContainer = styled.div`
  padding: 4rem 1rem;
  @media (min-width: 768px) {
    padding: 4rem;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 4rem 1rem;
  row-gap: 3rem;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  padding-bottom: 1rem;
  @media (min-width: 768px) {
    display: grid;
    padding: 4rem 2rem;
    padding-bottom: 1rem;
    row-gap: 5rem;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }
`

const FlipOrderImg = styled(Img)`
  order: 2;
  @media (min-width: 768px) {
    order: initial;
  }
`

const FlipOrderDiv = styled.div`
  order: 1;
  @media (min-width: 768px) {
    order: initial;
  }
`

const TeamContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

const DesktopBackground = styled(BackgroundImage)`
  background-attachment: scroll;
  @media (min-width: 1024px) {
    background-attachment: fixed;
  }
`

const MobileBackground = styled(BackgroundImage)`
  display: block;
  @media (min-width: 1024px) {
    display: none;
  }
`

const Title = styled.h1`
  text-align: center;
  padding: 2rem;
  padding-top: 10rem;
  color: white;
  @media (min-width: 1440px) {
    padding-top: 15rem;
  }
  @media (min-width: 2560px) {
    padding-top: 25rem;
  }
`

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaq(sort: { fields: orderTag }) {
        nodes {
          question
          answer {
            json
          }
        }
      }
      allContentfulAboutPageItems(sort: { fields: orderTag }) {
        nodes {
          header
          description {
            json
          }
          image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <SEO title="About Us" />

      <Reveal>
        {data.allContentfulAboutPageItems.nodes.slice(4, 5).map(item => {
          return (
            <BackgroundImage
              fluid={[
                `linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0.3)
              )`,
                item.image.fluid,
              ]}
              alt="banner"
              style={{
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <Header textColor="white" logoColor="white" />
              <Title style={{ fontSize: "1.8rem" }}>
                <b>About Us</b>
              </Title>
            </BackgroundImage>
          )
        })}
        <ContentContainer>
          {data.allContentfulAboutPageItems.nodes.slice(0, 1).map(item => {
            return (
              <>
                <div style={{ marginBottom: "1rem", padding: "2rem" }}>
                  <h3 style={{ fontSize: "1.7rem", marginBottom: "1rem" }}>
                    {item.header}
                  </h3>
                  {documentToReactComponents(item.description.json, options)}
                </div>
                <Img
                  fluid={item.image.fluid}
                  alt="First image"
                  style={{
                    borderRadius: "5px",
                    width: "80%",
                    marginBottom: "2rem",
                  }}
                />
              </>
            )
          })}
          {data.allContentfulAboutPageItems.nodes.slice(1, 2).map(item => {
            return (
              <>
                <FlipOrderImg
                  fluid={item.image.fluid}
                  alt="Second image"
                  style={{
                    borderRadius: "5px",
                    width: "80%",
                  }}
                />
                <FlipOrderDiv style={{ padding: "2rem" }}>
                  <h3 style={{ fontSize: "1.7rem", marginBottom: "1rem" }}>
                    {item.header}
                  </h3>

                  {documentToReactComponents(item.description.json, options)}
                </FlipOrderDiv>
              </>
            )
          })}
          {data.allContentfulAboutPageItems.nodes.slice(2, 3).map(item => {
            return (
              <>
                <div style={{ marginBottom: "1rem", padding: "2rem" }}>
                  <h3 style={{ fontSize: "1.7rem", marginBottom: "1rem" }}>
                    {item.header}
                  </h3>
                  {documentToReactComponents(item.description.json, options)}
                </div>
                <Img
                  fluid={item.image.fluid}
                  alt="Third image"
                  style={{
                    borderRadius: "5px",
                    width: "80%",
                  }}
                />
              </>
            )
          })}
        </ContentContainer>
        <div
          style={{
            textAlign: "center",
            marginBottom: "3rem",
            marginTop: "2rem",
          }}
        >
          <Link
            style={{
              textDecoration: "none",
              color: "var(--green)",
              fontWeight: "700",
              fontSize: "1.1rem",
            }}
            to="/faq"
          >
            Click here for more FAQ's
          </Link>
        </div>
        {data.allContentfulAboutPageItems.nodes.slice(3, 4).map(item => {
          return (
            <>
              <DesktopBackground
                fluid={item.image.fluid}
                alt="Fourth image"
                style={{
                  textAlign: "center",
                  padding: "4rem",
                  backgrounPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div style={{ height: "30rem" }}></div>
              </DesktopBackground>
              {/* <MobileBackground
                fluid={item.image.fluid}
                alt="Fourth image"
                style={{
                  textAlign: "center",
                  padding: "4rem",
                  backgrounPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div style={{ height: "30rem" }}></div>
              </MobileBackground> */}
              <ResponsiveContainer style={{ textAlign: "center" }}>
                <h3
                  style={{
                    fontSize: "1.7rem",
                    marginBottom: "2rem",
                  }}
                >
                  {item.header}
                </h3>
                {documentToReactComponents(item.description.json, options)}
                <br />
                <Link
                  style={{
                    textDecoration: "none",
                    color: "var(--green)",
                    fontWeight: "700",
                    fontSize: "1.1rem",
                  }}
                  to="/guiding-criteria"
                >
                  View our guiding criteria
                </Link>
              </ResponsiveContainer>
            </>
          )
        })}
        {data.allContentfulAboutPageItems.nodes.slice(5, 6).map(item => {
          return (
            <TeamContainer>
              <div style={{ backgroundColor: "#d4e6c9", padding: "1rem" }}>
                <img
                  src={TeamPhoto}
                  alt="Team photo"
                  style={{ padding: "2rem" }}
                />
              </div>
              <div
                style={{
                  textAlign: "left",
                  padding: "7rem 4rem",
                  backgroundColor: "#e9eff9",
                }}
              >
                <h3 style={{ marginBottom: "2rem", fontSize: "1.5rem" }}>
                  <b>{item.header}</b>
                </h3>
                <div>
                  {documentToReactComponents(item.description.json, options)}
                </div>
              </div>
            </TeamContainer>
          )
        })}
        <div
          style={{
            padding: "4rem 2rem",
            backgroundColor: "#D3D3D3",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div style={{ maxWidth: "1200px" }}>
            {data.allContentfulAboutPageItems.nodes.slice(7, 8).map(item => {
              return (
                <div>
                  <h3 style={{ fontSize: "1.5rem", paddingLeft: "2rem" }}>
                    {item.header}
                  </h3>
                  <div>
                    <div style={{ marginTop: "1rem", paddingLeft: "2rem" }}>
                      {documentToReactComponents(
                        item.description.json,
                        options
                      )}
                    </div>
                  </div>
                </div>
              )
            })}

            <div
              style={{
                justifyContent: "center",
                display: "flex",
                flexFlow: "row wrap",
                padding: "3rem 0 1rem 0",
              }}
            >
              <Supporter name="You Change Earth" photo={Logo} />
              <Supporter name="You Change Earth" photo={Logo} />
              <Supporter name="You Change Earth" photo={Logo} />
              <Supporter name="You Change Earth" photo={Logo} />
              <Supporter name="You Change Earth" photo={Logo} />
              <Supporter name="You Change Earth" photo={Logo} />
            </div>
          </div>
        </div>
        {data.allContentfulAboutPageItems.nodes.slice(6, 7).map(item => {
          return (
            <ResponsiveContainer>
              <h3 style={{ fontSize: "1.5rem", marginBottom: "2rem" }}>
                {item.header}
              </h3>
              <div style={{ textAlign: "center" }}>
                {documentToReactComponents(item.description.json, options)}
              </div>
            </ResponsiveContainer>
          )
        })}
      </Reveal>
      <Footer />
    </div>
  )
}

export default About
